module controllers {
    export module applicationcore {
        
        export class lookupCtrl {
            static $inject = ['$scope', '$rootScope', '$uibModalInstance', 'items',
                'skuService'];

            public searchAccordian = true;

            public search = {
                Code: '',
                Description: ''
            }
            
            IsLoading = false;
            public showGrid = false;
            apiGrid: uiGrid.IGridApi;
            pageSize = 25;
            currentPage = 1;
            public gvwLookupList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                paginationPageSizes: [25, 50, 75],
                paginationPageSize: this.pageSize,
                enableHorizontalScrollbar: 2,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: []
            };

            constructor(private $scope: ng.IScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, private items: interfaces.applicationcore.ILookupDirective,
                private skuService: interfaces.master.ISKUService) {
                this.loadGrid();
            }

            selectItem(Id: number, Code: string, Description: string) {
                var response: interfaces.applicationcore.ILookupResponse = {
                    id: Id,
                    code: Code,
                    description: Description
                }

                this.$uibModalInstance.close(response);
            };

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiGrid = gridApi;
                this.apiGrid.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => {
                    this.pageSize = pageSize;
                    this.currentPage = newPage;
                    this.loadData();
                });
                this.apiGrid.core.on.filterChanged(this.$scope, () => { this.loadData() });
                this.apiGrid.core.on.sortChanged(this.$scope, () => { this.loadData() });
            }

            search_click() {
                this.loadData();
            }

            showAll_click() {
                this.search = {
                    Code: '',
                    Description: ''
                };

                if (this.apiGrid) {
                    this.apiGrid.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiGrid.grid.resetColumnSorting(null);
                        this.loadData();
                    });
                } else {
                    this.loadData();
                }
            }

            loadGrid() {
                this.gvwLookupList.columnDefs = [];

                this.gvwLookupList.columnDefs.push({
                    name: "SELECT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.LookupCtrl.selectItem(row.entity.Id, row.entity.Code, row.entity.Description)" class="btn btn-default btn-sm">
                            <span class="fa fa-check"></span>
                        </button>
                    </div>`,
                    width: 35
                });

                switch (this.items.type) {
                }
            }

            loadData() {
                this.showGrid = true;
                switch (this.items.type) {
                }
            }

            cancel() {
                this.$uibModalInstance.dismiss('cancel');
            };

            addColumn(name:string, field: string, displayName?: string) {
                this.gvwLookupList.columnDefs.push({
                    name: name,
                    displayName: displayName ? displayName : field,
                    field: field,
                    enableFiltering: true,
                    enableSorting: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                });
            }
        };

        angular.module("app").controller("lookupCtrl", controllers.applicationcore.lookupCtrl);
    }
}